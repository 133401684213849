import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Button, SidebarMenu } from 'nbds-react';

import { AuthContext } from 'contexts/authentication/AuthContext';
import { LanguageContext } from 'contexts/language/LanguageContext';
import LanguageSelector from 'contexts/language/LanguageSelector';
import UserIcon from './UserIcon';

function UserMenu({
  expanded,
  setExpanded,
}) {
  const { isAuthenticated, logout, user } = useContext(AuthContext);
  const { translations } = useContext(LanguageContext);
  const userMessage = isAuthenticated ? `${translations.loggedInMessage} ${user.name}.` : translations.loggedOutMessage;

  function handleLogout() {
    setExpanded(false);
    logout();
  }

  return (
    <SidebarMenu
      icon={<UserIcon />}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <LanguageSelector />
      <div className="app-header-user">
        <p className="app-header-user-message">{userMessage}</p>
        {
          isAuthenticated && (
            <Button
              text={translations.logoutSubmit}
              variant="secondary"
              width="max"
              onClick={handleLogout}
            />
          )
        }
      </div>
    </SidebarMenu>
  );
}

UserMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
};

export default UserMenu;
