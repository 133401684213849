import React, { useState } from 'react';

import imgSrc from './ail-plus-logo.svg';
import UserMenu from './UserMenu';
import './AppHeader.scss';

function AppHeader() {
  const [showUserMenu, setShowUserMenu] = useState(false);

  return (
    <header className="app-header">
      <div className="app-header-content">
        <a href="/" className="app-header-logo"><img src={imgSrc} alt="Home" /></a>
        <UserMenu expanded={showUserMenu} setExpanded={setShowUserMenu} />
      </div>
    </header>
  );
}

export default AppHeader;
