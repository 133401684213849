import React, { useContext } from 'react';

import { Select } from 'nbds-react';

import { options } from './languages';
import { LanguageContext } from './LanguageContext';

export default function LanguageSelector() {
  const { language, setLanguage, translations } = useContext(LanguageContext);

  function handleChange(e) {
    setLanguage(e.target.value);
  }

  return (
    <Select
      label={translations.languageSelectorLabel}
      id="app-language-selector"
      name="app-language-selector"
      value={language}
      onChange={handleChange}
    >
      {Object.entries(options).map(([key, value]) => (
        <option key={key} value={key}>{value}</option>
      ))}
    </Select>
  );
}
