import en from './en.json';
import es from './es.json';

export const languages = {
  en,
  es,
};

export const options = {
  en: 'English',
  es: 'Spanish',
};
