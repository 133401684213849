import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserManager } from 'oidc-client';
import PropTypes from 'prop-types';

export const AuthContext = createContext({});

function AuthProvider({
  children,
}) {
  const [state, setState] = useState({
    isAuthenticated: false,
    user: null,
  });

  const userManager = useMemo(() => {
    const settings = {
      authority: process.env.REACT_APP_AUTH_AUTHORITY,
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      redirect_uri: `${window.location.origin}/callback.html`,
      response_type: 'code',
      scope: 'openid profile ailapi.read ailapi.write',
      post_logout_redirect_uri: `${window.location.origin}`,
    };

    return new UserManager(settings);
  }, []);

  const providerConfig = useMemo(() => {
    function getUser() {
      return userManager.getUser();
    }

    function login() {
      return userManager.signinRedirect();
    }

    function logout() {
      return userManager.signoutRedirect();
    }

    return {
      isAuthenticated: state.isAuthenticated,
      getUser,
      login,
      logout,
      user: state.user,
    };
  }, [state, userManager]);

  useEffect(() => {
    userManager.getUser().then((user) => {
      if (user) {
        setState({
          isAuthenticated: true,
          user: user.profile,
        });
      } else {
        setState({
          isAuthenticated: false,
          user: null,
        });
      }
    });
  }, [userManager]);

  return (
    <AuthContext.Provider value={providerConfig}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
