import React, { useMemo, useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { languages, options } from './languages';

export const LanguageContext = createContext({
  language: 'en',
  translations: languages.en,
});

function LanguageProvider({
  children,
}) {
  const [language, setLanguage] = useState(window.localStorage.getItem('isoLang') || 'en');

  const providerConfig = useMemo(() => {
    function handleChange(value) {
      const selected = options[value] ? value : 'en';

      setLanguage(selected);
      window.localStorage.setItem('isoLang', selected);
    }

    return {
      language,
      translations: languages[language],
      setLanguage: handleChange,
    };
  }, [language]);

  return (
    <LanguageContext.Provider value={providerConfig}>
      {children}
    </LanguageContext.Provider>
  );
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
