import React from 'react';

import AppFooter from 'components/app-footer/AppFooter';
import AppHeader from 'components/app-header/AppHeader';
import AppRoutes from 'components/app-routes/AppRoutes';
import AuthProvider from 'contexts/authentication/AuthContext';
import LanguageProvider from 'contexts/language/LanguageContext';

import './App.scss';

function App() {
  return (
    <AuthProvider>
      <LanguageProvider>
        <AppHeader />
        <AppRoutes />
        <AppFooter />
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;
