import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AuthContext } from 'contexts/authentication/AuthContext';

function PrivateRoutes() {
  const { isAuthenticated } = useContext(AuthContext);
  const { pathname } = useLocation();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" state={{ path: pathname }} replace />;
}

export default PrivateRoutes;
