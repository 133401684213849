import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Preloader } from 'nbds-react';

import PrivateRoutes from 'contexts/authentication/PrivateRoutes';

const Login = lazy(() => import('pages/login/Login'));
const Member = lazy(() => import('pages/member/Member'));
const NotFound = lazy(() => import('pages/not-found/NotFound'));

function AppRoutes() {
  return (
    <main className="app-main">
      <Suspense fallback={<Preloader message="Loading Content..." />}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/member/*" element={<Member />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </main>
  );
}

export default AppRoutes;
