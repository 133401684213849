import React, { useContext } from 'react';

import { Footer } from 'nbds-react';

import { LanguageContext } from 'contexts/language/LanguageContext';
import './AppFooter.scss';

function AppFooter() {
  const { translations } = useContext(LanguageContext);

  const apple = {
    alt: 'Download the app in the Apple App Store',
    src: `${process.env.PUBLIC_URL}/app-store.svg`,
    url: 'https://apps.apple.com/us/app/ail-plus/id1502363340',
  };

  const google = {
    alt: 'Download the app in the Google Play Store',
    src: `${process.env.PUBLIC_URL}/google-play.svg`,
    url: 'https://play.google.com/store/apps/details?id=com.newbenefits.ailplus',
  };

  const copyright = (
    <>
      <p>{`© ${`2000-${new Date().getFullYear()}`} ${translations.footerCopyright}`}</p>
      <a href={apple.url} rel="noopener noreferrer" target="_blank">
        <img src={apple.src} alt={apple.alt} />
      </a>
      <a href={google.url} rel="noopener noreferrer" target="_blank">
        <img src={google.src} alt={google.alt} />
      </a>
    </>
  );

  return (
    <Footer copyright={copyright} />
  );
}

export default AppFooter;
